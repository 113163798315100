import React, { useEffect, useState, useCallback } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';
import Pagination from '../components/Pagination';
import Select from '../components/Select';

let types = [
    {id: 0, name: 'pending'},
    {id: 1, name: 'active'},
    {id: 9, name: 'deleted'}
]
export default function Users(props){
    const [users, setUsers] = useState([])
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(props.match.params.page || 1);
    const [totalPages, setTotalPages] = useState(0);
    const perPage = 15;

    useEffect(()=>{
        getUsers();
    },[filter])
    
    const getUsers=()=>{
        Service.get("/users/get",{
            perPage,
            page,
            filter
        },(res) => {
            if(res.success){
                setTotalPages(res.total_pages);
                setUsers(res.users);
            }
        });
    }

    const setStatus=(status, userId)=>{
        console.log('set status', status, userId);
        Service.get('/users/setStatus', {userId, status: status.toString()}, (res)=>{
            if(res.success){
                getUsers();
                alert('Status changed');
            }
        })
    }

    const handleFilter = (e) =>{
        setPage(1);
        setFilter(e.target.value);
    }

    return(
        <div className="admin">
        <HeaderAdmin />
            <div className="admin-content">
                <h1>Users</h1>
                <div className='search-container'><input placeholder="search..." type="text" value={filter} onChange={(e)=>{handleFilter(e)}} /></div>

                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(function(item, index){
                                return(
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>
                                            <Select placeholder="Select type..." id='id' value='name' options={types} selected={item.status} onSelect={(res)=>{setStatus(res, item.id)}}/>
                                        </td>
                                    </tr>
                                )
                            },this)
                        }
                    </tbody>
                </table>

                <Pagination source={'users'} page={page} totalPages={totalPages}/>
            </div>
        

            <div className="admin-controls">
               
            </div>
        </div>
    )
}