import { Auth } from '../components/Auth';

var api = 'https://jobeograd.org/admin';
var images = 'https://jobeograd.org/admin/media/';
var paymentUrl = '';
var gaCode = 'UA-000000-01';
var secondaryImages = 'https://jobeograd.org/secondary-images/';

if(window.location.origin === 'https://admin.job.brainit.rs'){
    api = 'https://vp.pp.cekaonica.com/admin';
    // api = 'https://api.job.brainit.rs/admin';
    // api = 'http://localhost:8080';
    images = 'http://api.shop.brainit.rs/media/';
    paymentUrl = '';
    secondaryImages = 'http://api.shop.brainit.rs/secondary-images/';
}
if(window.location.origin === 'http://localhost:3000'){
    api = 'https://vp.pp.cekaonica.com/admin';
    // api = 'https://api.job.brainit.rs/admin';
    // api = 'http://localhost:8080';
    images = 'http://api.shop.brainit.rs/media/';
    paymentUrl = '';
    secondaryImages = 'http://api.shop.brainit.rs/secondary-images/';
}

const Service = {
    api: api,
    paymentUrl: paymentUrl,
    gaCode: gaCode,
    secondaryImages: secondaryImages,
    shippingFee: 150,

    images(image){
        return images + image;
    },

    get(url, params, cb=null){
        params.Auth = Auth;

        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(result.error === 'unauthorized'){
                    // window.location.replace('/');
                }
                if(cb){
                  if(result.message){
                    alert(result.message);
                  }
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    uploadImage(formData, cb){
      formData.append('Auth', JSON.stringify(Auth));
      fetch(`${api}/media/uploadImages`,
          {
            method: 'post',
            //headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: formData
           })
          .then(res => res.json())
          .then(
            (result) => {
              //console.log(result);
                cb(result);
                // this.setState({image: result.data});
                //this.getNews();
            },
            (error) => {
              console.log('error mus')
            }
        )
    },

    uploadDocument(formData, cb){
      fetch(`${api}/documents/uploadDocument`,
      {
        method: 'post',
        body: formData
        })
      .then(res => res.json())
      .then(
        (result) => {
            cb(result);
        },
        (error) => {
          console.log('error mus')
        }
      )
    },

    getProducts(cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 20,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getPendingProducts(cb=null){
        fetch(`${api}/products/getPending`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 50,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getTopProducts(params,cb=null){
        fetch(`${api}/products/top`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
              if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
          )
    },

    getProductById(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getProductBySearch(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewProducts(cb=null){
        fetch(`${api}/products/getnewproductreleases`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    
    addToWishlist(params,cb=null){
        fetch(`${api}/users/addProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    removeFromWishList(params,cb=null){
        fetch(`${api}/users/removeProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getWishlist(params,cb=null){
        fetch(`${api}/users/getProducts`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getProductsByCategory(params, cb=null){

        fetch(`${api}/products/getByCategory`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(
              params
            )
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    register(email, password, name, subscribe, cb=null) {

        // return;
        fetch(`${api}/users/create`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
                name: name,
                subscribe: subscribe,
                email: email,
                password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    login(email, password, cb=null) {
        fetch(`${api}/users/login`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              email: email,
              password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }

            },
            (error) => {

            }
        )
    },

    createOrder(params,cb=null) {

        fetch(`${api}/orders/create`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    addComment(params,cb=null){
        fetch(`${api}/products/addComment`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getComments(params,cb=null){
        fetch(`${api}/products/getComments`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNews(cb=null){
        fetch(`${api}/news/all`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewsSingle(params,cb=null){
        fetch(`${api}/news/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    krckoSubscribe(params,cb=null){
        fetch(`${api}/krcko/subscribe`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

      getCart(params,cb=null){

        fetch(`${api}/orders/cart`,
          {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    rateProduct(params,cb=null){

        fetch(`${api}/products/rate`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    }
    
 
}

export default Service;